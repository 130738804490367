export const namespaced = true;

export const state = {
  sl_a: 0,
  sl_b: 0,
  sl_c: 0,
  sl_d: 0,
  sl_e: 0,
  sl_f: 0,
  sl_g: 0,
  sl_h: 0,
  sl_i: 0,
  sl_j: 0,
  sl_k: 0,
  sl_l: 0,
  sl_m: 0,
  sl_n: 0,
  sl_o: 0,
  sl_p: 0,
  sl_q: 0,
  sl_r: 0,
  sl_s: 0,
  sl_t: 0,
  sl_u: 0,
  sl_v: 0,
  sl_w: 0,
  cb_a: false,
  cb_b: false,
  cb_c: false,
  cb_d: false,
  cb_e: false,
  cb_f: false,
  cb_g: false,
  cb_h: false,
  cb_i: false,
  cb_j: false,
  cb_k: false,
  cb_l: false,
  cb_m: false,
  cb_n: false,
  cb_o: false,
  cb_p: false,
  cb_q: false,
  cb_r: false,
  cb_s: false,
  cb_t: false,
  cb_u: false,
  cb_v: false,
  cb_w: false,
  question_a: "",
  question_b: "",
  question_c: "",
  question_d: "",
  question_e: "",
  question_f: "",
  question_g: "",
  question_h: "",
  question_i: "",
  question_j: "",
  question_k: "",
  question_l: "",
  question_m: "",
  question_n: "",
  question_o: "",
  question_p: "",
  question_q: "",
  question_r: "",
  question_s: "",
  question_t: "",
  question_u: "",
  question_v: "",
  question_w: "",
  question_x: "",
  question_y: "",
  question_z: "",
  question_aa: "",
  question_ab: "",
  question_ac: "",
  question_ad: "",
  question_ae: "",
  question_af: "",
  question_ag: "",
  question_ah: "",
  question_ai: "",
  question_aj: "",
  question_ak: "",
  question_al: "",
  question_am: "",
  question_an: "",
  question_ao: "",
  question_ap: "",
  question_aq: "",
  question_ar: "",
  question_as: "",
  question_at: "",
  question_au: "",
  question_av: "",
  question_aw: "",
  question_ax: "",
  question_ay: "",
  question_az: "",
  text_a: null,
  text_b: null,
  text_c: null,
  text_d: null,
  text_e: null,
  text_f: null,
  text_g: null,
  text_h: null,
  text_i: null,
  text_j: null,
  text_k: null,
  text_l: null,
  text_m: null,
  text_n: null,
  text_o: null,
};

export const mutations = {
  update_sl_a(state, sl_a) {
    state.sl_a = sl_a;
  },
  update_sl_b(state, sl_b) {
    state.sl_b = sl_b;
  },
  update_sl_c(state, sl_c) {
    state.sl_c = sl_c;
  },
  update_sl_d(state, sl_d) {
    state.sl_d = sl_d;
  },
  update_sl_e(state, sl_e) {
    state.sl_e = sl_e;
  },
  update_sl_f(state, sl_f) {
    state.sl_f = sl_f;
  },
  update_sl_g(state, sl_g) {
    state.sl_g = sl_g;
  },
  update_sl_h(state, sl_h) {
    state.sl_h = sl_h;
  },
  update_sl_i(state, sl_i) {
    state.sl_i = sl_i;
  },
  update_sl_j(state, sl_j) {
    state.sl_j = sl_j;
  },
  update_sl_k(state, sl_k) {
    state.sl_k = sl_k;
  },
  update_sl_l(state, sl_l) {
    state.sl_l = sl_l;
  },
  update_sl_m(state, sl_m) {
    state.sl_m = sl_m;
  },
  update_sl_n(state, sl_n) {
    state.sl_n = sl_n;
  },
  update_sl_o(state, sl_o) {
    state.sl_o = sl_o;
  },
  update_sl_p(state, sl_p) {
    state.sl_p = sl_p;
  },
  update_sl_q(state, sl_q) {
    state.sl_q = sl_q;
  },
  update_sl_r(state, sl_r) {
    state.sl_r = sl_r;
  },
  update_sl_s(state, sl_s) {
    state.sl_s = sl_s;
  },
  update_sl_t(state, sl_t) {
    state.sl_t = sl_t;
  },
  update_sl_u(state, sl_u) {
    state.sl_u = sl_u;
  },
  update_sl_v(state, sl_v) {
    state.sl_v = sl_v;
  },
  update_sl_w(state, sl_w) {
    state.sl_w = sl_w;
  },
  update_cb_a(state, cb_a) {
    state.cb_a = cb_a;
  },
  update_cb_b(state, cb_b) {
    state.cb_b = cb_b;
  },
  update_cb_c(state, cb_c) {
    state.cb_c = cb_c;
  },
  update_cb_d(state, cb_d) {
    state.cb_d = cb_d;
  },
  update_cb_e(state, cb_e) {
    state.cb_e = cb_e;
  },
  update_cb_f(state, cb_f) {
    state.cb_f = cb_f;
  },
  update_cb_g(state, cb_g) {
    state.cb_g = cb_g;
  },
  update_cb_h(state, cb_h) {
    state.cb_h = cb_h;
  },
  update_cb_i(state, cb_i) {
    state.cb_i = cb_i;
  },
  update_cb_j(state, cb_j) {
    state.cb_j = cb_j;
  },
  update_cb_k(state, cb_k) {
    state.cb_k = cb_k;
  },
  update_cb_l(state, cb_l) {
    state.cb_l = cb_l;
  },
  update_cb_m(state, cb_m) {
    state.cb_m = cb_m;
  },
  update_cb_n(state, cb_n) {
    state.cb_n = cb_n;
  },
  update_cb_o(state, cb_o) {
    state.cb_o = cb_o;
  },
  update_cb_p(state, cb_p) {
    state.cb_p = cb_p;
  },
  update_cb_q(state, cb_q) {
    state.cb_q = cb_q;
  },
  update_cb_r(state, cb_r) {
    state.cb_r = cb_r;
  },
  update_cb_s(state, cb_s) {
    state.cb_s = cb_s;
  },
  update_cb_t(state, cb_t) {
    state.cb_t = cb_t;
  },
  update_cb_u(state, cb_u) {
    state.cb_u = cb_u;
  },
  update_cb_v(state, cb_v) {
    state.cb_v = cb_v;
  },
  update_cb_w(state, cb_w) {
    state.cb_w = cb_w;
  },
  update_question_a(state, question_a) {
    state.question_a = question_a;
  },
  update_question_b(state, question_b) {
    state.question_b = question_b;
  },
  update_question_c(state, question_c) {
    state.question_c = question_c;
  },
  update_question_d(state, question_d) {
    state.question_d = question_d;
  },
  update_question_e(state, question_e) {
    state.question_e = question_e;
  },
  update_question_f(state, question_f) {
    state.question_f = question_f;
  },
  update_question_g(state, question_g) {
    state.question_g = question_g;
  },
  update_question_h(state, question_h) {
    state.question_h = question_h;
  },
  update_question_i(state, question_i) {
    state.question_i = question_i;
  },
  update_question_j(state, question_j) {
    state.question_j = question_j;
  },
  update_question_k(state, question_k) {
    state.question_k = question_k;
  },
  update_question_l(state, question_l) {
    state.question_l = question_l;
  },
  update_question_m(state, question_m) {
    state.question_m = question_m;
  },
  update_question_n(state, question_n) {
    state.question_n = question_n;
  },
  update_question_o(state, question_o) {
    state.question_o = question_o;
  },
  update_question_p(state, question_p) {
    state.question_p = question_p;
  },
  update_question_q(state, question_q) {
    state.question_q = question_q;
  },
  update_question_r(state, question_r) {
    state.question_r = question_r;
  },
  update_question_s(state, question_s) {
    state.question_s = question_s;
  },
  update_question_t(state, question_t) {
    state.question_t = question_t;
  },
  update_question_u(state, question_u) {
    state.question_u = question_u;
  },
  update_question_v(state, question_v) {
    state.question_v = question_v;
  },
  update_question_w(state, question_w) {
    state.question_w = question_w;
  },
  update_question_x(state, question_x) {
    state.question_x = question_x;
  },
  update_question_y(state, question_y) {
    state.question_y = question_y;
  },
  update_question_z(state, question_z) {
    state.question_z = question_z;
  },
  update_question_aa(state, question_aa) {
    state.question_aa = question_aa;
  },
  update_question_ab(state, question_ab) {
    state.question_ab = question_ab;
  },
  update_question_ac(state, question_ac) {
    state.question_ac = question_ac;
  },
  update_question_ad(state, question_ad) {
    state.question_ad = question_ad;
  },
  update_question_ae(state, question_ae) {
    state.question_ae = question_ae;
  },
  update_question_af(state, question_af) {
    state.question_af = question_af;
  },
  update_question_ag(state, question_ag) {
    state.question_ag = question_ag;
  },
  update_question_ah(state, question_ah) {
    state.question_ah = question_ah;
  },
  update_question_ai(state, question_ai) {
    state.question_ai = question_ai;
  },
  update_question_aj(state, question_aj) {
    state.question_aj = question_aj;
  },
  update_question_ak(state, question_ak) {
    state.question_ak = question_ak;
  },
  update_question_al(state, question_al) {
    state.question_al = question_al;
  },
  update_question_am(state, question_am) {
    state.question_am = question_am;
  },
  update_question_an(state, question_an) {
    state.question_an = question_an;
  },
  update_question_ao(state, question_ao) {
    state.question_ao = question_ao;
  },
  update_question_ap(state, question_ap) {
    state.question_ap = question_ap;
  },
  update_question_aq(state, question_aq) {
    state.question_aq = question_aq;
  },
  update_question_ar(state, question_ar) {
    state.question_ar = question_ar;
  },
  update_question_as(state, question_as) {
    state.question_as = question_as;
  },
  update_question_at(state, question_at) {
    state.question_at = question_at;
  },
  update_question_au(state, question_au) {
    state.question_au = question_au;
  },
  update_question_av(state, question_av) {
    state.question_av = question_av;
  },
  update_question_aw(state, question_aw) {
    state.question_aw = question_aw;
  },
  update_question_ax(state, question_ax) {
    state.question_ax = question_ax;
  },
  update_question_ay(state, question_ay) {
    state.question_ay = question_ay;
  },
  update_question_az(state, question_az) {
    state.question_az = question_az;
  },
  update_text_a(state, text_a) {
    state.text_a = text_a;
  },
  update_text_b(state, text_b) {
    state.text_b = text_b;
  },
  update_text_c(state, text_c) {
    state.text_c = text_c;
  },
  update_text_d(state, text_d) {
    state.text_d = text_d;
  },
  update_text_e(state, text_e) {
    state.text_e = text_e;
  },
  update_text_f(state, text_f) {
    state.text_f = text_f;
  },
  update_text_g(state, text_g) {
    state.text_g = text_g;
  },
  update_text_h(state, text_h) {
    state.text_h = text_h;
  },
  update_text_i(state, text_i) {
    state.text_i = text_i;
  },
  update_text_j(state, text_j) {
    state.text_j = text_j;
  },
  update_text_k(state, text_k) {
    state.text_k = text_k;
  },
  update_text_l(state, text_l) {
    state.text_l = text_l;
  },
  update_text_m(state, text_m) {
    state.text_m = text_m;
  },
  update_text_n(state, text_n) {
    state.text_n = text_n;
  },
  update_text_o(state, text_o) {
    state.text_o = text_o;
  },
};
export const getters = {
  sl_a: (state) => {
    return state.sl_a;
  },
  sl_b: (state) => {
    return state.sl_b;
  },
  sl_c: (state) => {
    return state.sl_c;
  },
  sl_d: (state) => {
    return state.sl_d;
  },
  sl_e: (state) => {
    return state.sl_e;
  },
  sl_f: (state) => {
    return state.sl_f;
  },
  sl_g: (state) => {
    return state.sl_g;
  },
  sl_h: (state) => {
    return state.sl_h;
  },
  sl_i: (state) => {
    return state.sl_i;
  },
  sl_j: (state) => {
    return state.sl_j;
  },
  sl_k: (state) => {
    return state.sl_k;
  },
  sl_l: (state) => {
    return state.sl_l;
  },
  sl_m: (state) => {
    return state.sl_m;
  },
  sl_n: (state) => {
    return state.sl_n;
  },
  sl_o: (state) => {
    return state.sl_o;
  },
  sl_p: (state) => {
    return state.sl_p;
  },
  sl_q: (state) => {
    return state.sl_q;
  },
  sl_r: (state) => {
    return state.sl_r;
  },
  sl_s: (state) => {
    return state.sl_s;
  },
  sl_t: (state) => {
    return state.sl_t;
  },
  sl_u: (state) => {
    return state.sl_u;
  },
  sl_v: (state) => {
    return state.sl_v;
  },
  sl_w: (state) => {
    return state.sl_w;
  },
  cb_a: (state) => {
    return state.cb_a;
  },
  cb_b: (state) => {
    return state.cb_b;
  },
  cb_c: (state) => {
    return state.cb_c;
  },
  cb_d: (state) => {
    return state.cb_d;
  },
  cb_e: (state) => {
    return state.cb_e;
  },
  cb_f: (state) => {
    return state.cb_f;
  },
  cb_g: (state) => {
    return state.cb_g;
  },
  cb_h: (state) => {
    return state.cb_h;
  },
  cb_i: (state) => {
    return state.cb_i;
  },
  cb_j: (state) => {
    return state.cb_j;
  },
  cb_k: (state) => {
    return state.cb_k;
  },
  cb_l: (state) => {
    return state.cb_l;
  },
  cb_m: (state) => {
    return state.cb_m;
  },
  cb_n: (state) => {
    return state.cb_n;
  },
  cb_o: (state) => {
    return state.cb_o;
  },
  cb_p: (state) => {
    return state.cb_p;
  },
  cb_q: (state) => {
    return state.cb_q;
  },
  cb_r: (state) => {
    return state.cb_r;
  },
  cb_s: (state) => {
    return state.cb_s;
  },
  cb_t: (state) => {
    return state.cb_t;
  },
  cb_u: (state) => {
    return state.cb_u;
  },
  cb_v: (state) => {
    return state.cb_v;
  },
  cb_w: (state) => {
    return state.cb_w;
  },
  question_a: (state) => {
    return state.question_a;
  },
  question_b: (state) => {
    return state.question_b;
  },
  question_c: (state) => {
    return state.question_c;
  },
  question_d: (state) => {
    return state.question_d;
  },
  question_e: (state) => {
    return state.question_e;
  },
  question_f: (state) => {
    return state.question_f;
  },
  question_g: (state) => {
    return state.question_g;
  },
  question_h: (state) => {
    return state.question_h;
  },
  question_i: (state) => {
    return state.question_i;
  },
  question_j: (state) => {
    return state.question_j;
  },
  question_k: (state) => {
    return state.question_k;
  },
  question_l: (state) => {
    return state.question_l;
  },
  question_m: (state) => {
    return state.question_m;
  },
  question_n: (state) => {
    return state.question_n;
  },
  question_o: (state) => {
    return state.question_o;
  },
  question_p: (state) => {
    return state.question_p;
  },
  question_q: (state) => {
    return state.question_q;
  },
  question_r: (state) => {
    return state.question_r;
  },
  question_s: (state) => {
    return state.question_s;
  },
  question_t: (state) => {
    return state.question_t;
  },
  question_u: (state) => {
    return state.question_u;
  },
  question_v: (state) => {
    return state.question_v;
  },
  question_w: (state) => {
    return state.question_w;
  },
  question_x: (state) => {
    return state.question_x;
  },
  question_y: (state) => {
    return state.question_y;
  },
  question_z: (state) => {
    return state.question_z;
  },
  question_aa: (state) => {
    return state.question_aa;
  },
  question_ab: (state) => {
    return state.question_ab;
  },
  question_ac: (state) => {
    return state.question_ac;
  },
  question_ad: (state) => {
    return state.question_ad;
  },
  question_ae: (state) => {
    return state.question_ae;
  },
  question_af: (state) => {
    return state.question_af;
  },
  question_ag: (state) => {
    return state.question_ag;
  },
  question_ah: (state) => {
    return state.question_ah;
  },
  question_ai: (state) => {
    return state.question_ai;
  },
  question_aj: (state) => {
    return state.question_aj;
  },
  question_ak: (state) => {
    return state.question_ak;
  },
  question_al: (state) => {
    return state.question_al;
  },
  question_am: (state) => {
    return state.question_am;
  },
  question_an: (state) => {
    return state.question_an;
  },
  question_ao: (state) => {
    return state.question_ao;
  },
  question_ap: (state) => {
    return state.question_ap;
  },
  question_aq: (state) => {
    return state.question_aq;
  },
  question_ar: (state) => {
    return state.question_ar;
  },
  question_as: (state) => {
    return state.question_as;
  },
  question_at: (state) => {
    return state.question_at;
  },
  question_au: (state) => {
    return state.question_au;
  },
  question_av: (state) => {
    return state.question_av;
  },
  question_aw: (state) => {
    return state.question_aw;
  },
  question_ax: (state) => {
    return state.question_ax;
  },
  question_ay: (state) => {
    return state.question_ay;
  },
  question_az: (state) => {
    return state.question_az;
  },
  text_a: (state) => {
    return state.text_a;
  },
  text_b: (state) => {
    return state.text_b;
  },
  text_c: (state) => {
    return state.text_c;
  },
  text_d: (state) => {
    return state.text_d;
  },
  text_e: (state) => {
    return state.text_e;
  },
  text_f: (state) => {
    return state.text_f;
  },
  text_g: (state) => {
    return state.text_g;
  },
  text_h: (state) => {
    return state.text_h;
  },
  text_i: (state) => {
    return state.text_i;
  },
  text_j: (state) => {
    return state.text_j;
  },
  text_k: (state) => {
    return state.text_k;
  },
  text_l: (state) => {
    return state.text_l;
  },
  text_m: (state) => {
    return state.text_m;
  },
  text_n: (state) => {
    return state.text_n;
  },
  text_o: (state) => {
    return state.text_o;
  },
};
export const actions = {
  setDefault({ commit }) {
    commit("update_sl_a", 0);
    commit("update_sl_b", 0);
    commit("update_sl_c", 0);
    commit("update_sl_d", 0);
    commit("update_sl_e", 0);
    commit("update_sl_f", 0);
    commit("update_sl_g", 0);
    commit("update_sl_h", 0);
    commit("update_sl_i", 0);
    commit("update_sl_j", 0);
    commit("update_sl_k", 0);
    commit("update_sl_l", 0);
    commit("update_sl_m", 0);
    commit("update_sl_n", 0);
    commit("update_sl_o", 0);
    commit("update_sl_p", 0);
    commit("update_sl_q", 0);
    commit("update_sl_r", 0);
    commit("update_sl_s", 0);
    commit("update_sl_t", 0);
    commit("update_sl_u", 0);
    commit("update_sl_v", 0);
    commit("update_sl_w", 0);
    commit("update_cb_a", false);
    commit("update_cb_b", false);
    commit("update_cb_c", false);
    commit("update_cb_d", false);
    commit("update_cb_e", false);
    commit("update_cb_f", false);
    commit("update_cb_g", false);
    commit("update_cb_h", false);
    commit("update_cb_i", false);
    commit("update_cb_j", false);
    commit("update_cb_k", false);
    commit("update_cb_l", false);
    commit("update_cb_m", false);
    commit("update_cb_n", false);
    commit("update_cb_o", false);
    commit("update_cb_p", false);
    commit("update_cb_q", false);
    commit("update_cb_r", false);
    commit("update_cb_s", false);
    commit("update_cb_t", false);
    commit("update_cb_u", false);
    commit("update_cb_v", false);
    commit("update_cb_w", false);
    commit("update_question_a", "");
    commit("update_question_b", "");
    commit("update_question_c", "");
    commit("update_question_d", "");
    commit("update_question_e", "");
    commit("update_question_f", "");
    commit("update_question_g", "");
    commit("update_question_h", "");
    commit("update_question_i", "");
    commit("update_question_j", "");
    commit("update_question_k", "");
    commit("update_question_l", "");
    commit("update_question_m", "");
    commit("update_question_n", "");
    commit("update_question_o", "");
    commit("update_question_p", "");
    commit("update_question_q", "");
    commit("update_question_r", "");
    commit("update_question_s", "");
    commit("update_question_t", "");
    commit("update_question_u", "");
    commit("update_question_v", "");
    commit("update_question_w", "");
    commit("update_question_x", "");
    commit("update_question_y", "");
    commit("update_question_z", "");
    commit("update_question_aa", "");
    commit("update_question_ab", "");
    commit("update_question_ac", "");
    commit("update_question_ad", "");
    commit("update_question_ae", "");
    commit("update_question_af", "");
    commit("update_question_ag", "");
    commit("update_question_ah", "");
    commit("update_question_ai", "");
    commit("update_question_aj", "");
    commit("update_question_ak", "");
    commit("update_question_al", "");
    commit("update_question_am", "");
    commit("update_question_an", "");
    commit("update_question_ao", "");
    commit("update_question_ap", "");
    commit("update_question_aq", "");
    commit("update_question_ar", "");
    commit("update_question_as", "");
    commit("update_question_at", "");
    commit("update_question_au", "");
    commit("update_question_av", "");
    commit("update_question_aw", "");
    commit("update_question_ax", "");
    commit("update_question_ay", "");
    commit("update_question_az", "");
    commit("update_text_a", 0);
    commit("update_text_b", 0);
    commit("update_text_c", 0);
    commit("update_text_d", 0);
    commit("update_text_e", 0);
    commit("update_text_f", 0);
    commit("update_text_g", 0);
    commit("update_text_h", 0);
    commit("update_text_i", 0);
    commit("update_text_j", 0);
    commit("update_text_k", 0);
    commit("update_text_l", 0);
    commit("update_text_m", 0);
    commit("update_text_n", 0);
    commit("update_text_o", 0);
  },
};
